<div class="handshake">
    <div class="content">
        <img id="handshake-icon" src="assets/img/svg/handshake.svg">
        <div class="text">
            <h2 id="title">Saily Trades FZE</h2>
            <p>Saily Trades FZE is a company located in Umm Al Quwain in the U.A.E. We import and export FMCG (Fast-Moving-Consumer-Goods) products within Asia. Our business of export and import within Asia is based on the idea to bring Western brands such as Red Bull, Ferrero, Coca Cola into the Asian market for affordable prices. We are also excited to offer European exquisiteness as pasta and cheese to the Asian market.</p>
        </div>
    </div>
    <div class="bottom-fade-out">
        <img id="red-people" src="assets/img/svg/red-people.svg">
        <div class="triangle-bottom"></div>
        <img id="van-delivery" src="assets/img/svg/van-delivery.svg">
    </div>
</div>
