import { Component, OnInit } from '@angular/core';
import anime from 'animejs/lib/anime.es';
import $ from 'jquery';
declare var Rellax: any;



@Component({
  selector: 'app-dubai-landscape',
  templateUrl: './dubai-landscape.component.html',
  styleUrls: ['./dubai-landscape.component.scss']
})
export class DubaiLandscapeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    
    if ($(window).width() <= 540) {
      console.log('hi');
      $("#skyline").attr("data-rellax-speed",1);
    }
    var rellax = new Rellax('.rellax');
  }

  ngAfterViewInit(): void {
    $(window).scroll(function(event) {
      $(".mark-top").each(function(i,el) {
        var el = $(el);
        if (el.visible(true)) {
          anime ({
            targets: '#icon-mouse_scroll_down',
            opacity: 1,
          });
        } else {
          anime ({
            targets: '#icon-mouse_scroll_down',
            opacity: 0,
        });
        }
      });
    });
  }

}
