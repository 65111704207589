<div class="contacts">
    <!-- particles.js container --> 
    <div id="particles-js"></div> 
    <div id="contacts-content">
        <h2 id="contacts-title">Are you ready to <span>S</span>AIL with us?</h2>
        <h4 id="contacts-text">We are always looking for new suppliers or <br>
        customers to expand our business.</h4>
        <h4 id="contacts-exclamation">For further information contact us!</h4>
        <table id="contacts-end-point">
            <tr>
                <td class="contact-header" style="color: #42a5f5">T</td>
                <td class="contact-data">+971 56 104 4970</td>
            </tr>
            <tr>
                <td class="contact-header" style="color: #1975d1">E</td>
                <td class="contact-data">info@sailytrades.ae</td>
            </tr>
            <tr>
                <td class="contact-header" style="color: #ff5252">Address</td>
                <td class="contact-data">
                    Business Center<br>
                    Al Shmookh Building<br>
                    UAQ Free Trade Zone<br>
                    Umm Al Quwain - UAE<br>
                </td>    
            </tr>
            <tr>
                <td class="contact-header" style="color: #ff5252">Office</td>
                <td class="contact-data"> 3507, 35th Floor,<br>
                    Burlington Tower,<br>
                    Al Abraj Street, Business Bay,<br>
                    Dubai - UAE<br>
                </td>
            </tr>
        </table>

<!--
        <div id="contact-points">
            <div class="sub-point">
                <h2>T</h2>
                <h3>+49 151 62656592</h3>
            </div>
            <div class="sub-point">
                <h2>E</h2>
                <h3>info@sailytrades.ae</h3>
            </div>
            <div class="sub-point">
                <h2>A</h2>
                <div class="address">
                    <h3>Business Center</h3><br>
                    <h3>Al Shmookh Building</h3><br>
                    <h3>UAQ Free Trade Zone</h3><br>
                    <h3>Umm Al Quwain - UAE</h3>
                </div>    
            </div>
        </div>
        -->
        <img id="footer-logo" src="assets/img/svg/logo.svg">
    </div>
</div>
