import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DubaiLandscapeComponent } from './sections/dubai-landscape/dubai-landscape.component';
import { HandshakeComponent } from './sections/handshake/handshake.component';
import { ProductsComponent } from './sections/products/products.component';
import { ContactsComponent } from './sections/contacts/contacts.component';


@NgModule({
  declarations: [
    AppComponent,
    DubaiLandscapeComponent,
    HandshakeComponent,
    ProductsComponent,
    ContactsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
