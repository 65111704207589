<div class="products">
  <div class="products-content">
    <div class="design-items">
      <div id="bands">
        <div id="band-red" class="module"></div>
        <div id="band-dark-blue"></div>
        <div id="band-light-blue"></div>
      </div>
      <div id="reference" class="reference"></div>
      <img id="iconspeaker" src="assets/img/svg/iconspeaker.svg" />
    </div>
    <div class="text">
      <p>
        Besides, our company has established our own branded products and
        introduced them to the FMCG market. In this sense, we are delighted to
        offer our own branded oil and flour. There is work in progress to launch
        other goods from our own brand within the FMCG market.
      </p>
      <p>
        Since our company is long established in the FMCG market and due to our
        extensive network, we are able to provide a diverse range of products at
        competitive prices to our clients. In addition to that, the B2B service
        of Saily Trades FZE supplies both intermediary and wholesaler.
      </p>
    </div>
  </div>
  <div class="bottom-fade-out">
    <div class="triangle-bottom-black"></div>
  </div>
</div>
