import { Component, OnInit, AfterViewInit } from '@angular/core';
import anime from 'animejs/lib/anime.es';
import $ from 'jquery';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, AfterViewInit {

  constructor() { 
    (function($) {
  
      $.fn.visible = function(partial) {
        
          var $t            = $(this),
              $w            = $(window),
              viewTop       = $w.scrollTop(),
              viewBottom    = viewTop + $w.height(),
              _top          = $t.offset().top,
              _bottom       = _top + $t.height(),
              compareTop    = partial === true ? _bottom : _top,
              compareBottom = partial === true ? _top : _bottom;
        
        return ((compareBottom <= viewBottom) && (compareTop >= viewTop));
    
      };
        
    })($);

  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    $(window).scroll(function(event) {
      $("#iconspeaker").each(function(i, el) {
        var el = $(el);
        if (el.visible(true)) {
          anime({
            targets: '#band-red',
            height: "120vh",
            duration: 2000,
            easing: "linear"
          });
        } else {
        }
      });
    });

    var animated = false;
    $(window).scroll(function(event) {
      $("#iconspeaker").each(function(i,el) {
        var el = $(el);
        if (el.visible(true) && animated === false) {
          anime({
            targets: '.triangle-bottom-black',
            translateX: ["-100vh", 0],
            duration: 1200,
            easing: "linear",
            delay: 500
          });
          anime({
            targets: '#iconspeaker',
            translateY: ["50px", 0],
            duration: 1200,
            easing: "easeInOutQuad",
          });
          animated = true;


        }
    });
  });

    $(window).scroll(function(event) {
    $(".reference").each(function(i,el) {
      var el = $(el);
      if (!el.visible(true)) {
        anime({
          targets: '#band-red',
          height: "0vh",
          duration: 2000,
          easing: "linear"
        });
    } else {
    }
  });
});
}
}
